<template>
  <v-dialog
    id="form_view"
    v-if="bActive"
    overlay-color="primary"
    overlay-opacity=".97"
    persistent
    :fullscreen="smUp ? false: true"
    max-width="40%"
    v-model="bActive">
    <v-card
      :shaped="smUp"
      class="dialog_card_container">
      <!-- Start close icon -->
      <v-layout
        row
        justify-end>
        <v-btn
          style="margin-right: 10px"
          icon
          fab
          small
          color="grey"
          text
          @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-layout>
      <!-- End close icon -->
      <!-- Start dialog title -->
      <v-container
        style="margin-top: -30px">
        <v-layout
          row
          justify-center>
          <p
            v-text="dialog_title"
            class="dialog_title_text"
          />
        </v-layout>
      </v-container>
      <!-- End dialog title -->
      <!-- Start form containr -->
      <v-container>
        <v-layout
          row
          justify-center>
          <v-layout
            column
            :align-center="!smUp"
            style="max-width: 80%">
            <!-- Start name textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="name_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="field_layout">
              <v-text-field 
                v-model="new_report.sTitle"
                color="primary"
                :placeholder="name_text"
              />
            </v-layout>
            <!-- End name textfield -->
            <!-- Start phone textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="yearText"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="field_layout">
              <v-select
                style="margin-top: 20px"
                :items="yearOptions"
                v-model="new_report.iYear"
                label="Year"
                class="customPlaceHolder"
                clearable>
                <template v-slot:no-data>
                  <p
                    class="selectNoData">
                    No years available for selection.
                  </p>
                </template>
                <template 
                  slot="item" 
                  slot-scope="data">
                  <v-layout
                    row
                    justify-center>
                    <p class="selectItem">
                      {{data.item}}
                    </p>
                  </v-layout>
                </template>
              </v-select>
            </v-layout>
            <!-- End phone textfield -->
            <!-- Start mail textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="monthText"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="field_layout">
              <v-select
                style="margin-top: 20px"
                :items="monthOptions"
                v-model="new_report.iMonth"
                label="Month"
                class="customPlaceHolder"
                clearable>
                <template v-slot:no-data>
                  <p
                    class="selectNoData">
                    No months available for selection.
                  </p>
                </template>
                <template 
                  slot="item" 
                  slot-scope="data">
                  <v-layout
                    row
                    justify-center>
                    <p class="selectItem">
                      {{data.item.text}}
                    </p>
                  </v-layout>
                </template>
              </v-select>
            </v-layout>
            <!-- End mail textfield -->
          </v-layout>
        </v-layout>
      </v-container>
      <!-- End form containr -->
      <v-container>
        <!-- Center button layout -->
        <v-layout
          row
          justify-center>
          <!-- Limit button layout extension -->
          <v-layout
            row
            style="max-width: 80%">
            <v-btn
              color="red"
              text
              @click="close">
              {{cancel_btn_txt}}
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn
              color="primary"
              dense
              :loading="load_add_btn"
              :disabled="load_add_btn"
              @click="confirm_add">
              {{submit_btn_txt}}
            </v-btn>
          </v-layout>
          <!-- Limit button layout extension -->
        </v-layout>
        <!-- Center button layout  -->
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  mounted () {
    this.fillYears()
  },
  data: () => ({
    monthSelected: null,
    yearSelected: null,
    yearOptions: [],
    monthOptions: [
      {
        text: 'January',
        value: 0,
      },
      {
        text: 'February',
        value: 1,
      },
      {
        text: 'March',
        value: 2,
      },
      {
        text: 'April',
        value: 3,
      },
      {
        text: 'May',
        value: 4,
      },
      {
        text: 'June',
        value: 5,
      },
      {
        text: 'July',
        value: 6,
      },
      {
        text: 'August',
        value: 7,
      },
      {
        text: 'September',
        value: 8,
      },
      {
        text: 'October',
        value: 9,
      },
      {
        text: 'November',
        value: 10,
      },
      {
        text: 'December',
        value: 11,
      }
    ],
    name_text: 'Title',
    yearText: 'Year',
    monthText: 'Month',
    submit_btn_txt: 'Save',
    cancel_btn_txt: 'Cancel',
    dialog_title: 'New Report',
    new_report: {
      sTitle: '',
      iYear: null,
      iMonth: null,
    },
    load_add_btn: false
  }),
  props: {
    bActive: Boolean
  },
  methods: {
    fillYears () {
      var currentYear = new Date().getFullYear()
      var startYear = 2020
      while ( startYear <= currentYear ) {
          this.yearOptions.push(startYear++);
      }   
    },
    close () {
      this.$emit('close')
    },
    reset_form () {
      this.new_report = {
        sTitle: '',
        iYear: null,
        iMonth: null,
      }
    },
    confirm_add () {
      this.load_add_btn = true
      var body = this.new_report
      // body.iYear = parseInt(body.iYear)
      // body.iMonth = parseInt(body.iMonth)
      body = encode.encodeJSON(this.new_report)
      db.post(`${uri}/v2/api/reports`, body, {
        headers: {
          'Authorization': this.usr_token,
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
      .then(resp => {
        this.load_add_btn = false
        const alert = {
          color: 'success',
          text: resp.data.message 
        }
        this.$store.commit('toggle_alert', alert)
        this.close()
        this.reset_form()
        this.$emit('update')
      })
      .catch(err => {
        this.load_add_btn = false
        const alert = {
          color: 'fail',
          text: err.response.data.message 
        }
        this.$store.commit('toggle_alert', alert)
      })
    },
  },
  computed: {
    checkFullForm () {
      return (this.new_report.sTitle && this.new_report.iYear && this.new_report.iMonth) ? false:true
    },
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    },
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    }
  }
}
</script>
<style>
  .customPlaceHolder .v-label {
    font-family: "Open sans", sans-serif !important;
    font-size: 11px !important;
    font-weight: bold !important;
    font-style: italic !important;
    margin-left: 6px !important;
    margin-bottom: 0px !important;
    color: rgba(0, 0, 0, 0.568) !important;
  }
</style>
<style scoped>
.selectNoData {
  margin: 10px;
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}
.selectItem {
  font-family: "Open sans", sans-serif !important;
  font-size: 11px !important;
  font-weight: bold !important;
  margin-left: 6px !important;
  margin-bottom: 0px !important;
  color: rgba(0, 0, 0, 0.568) !important;
  text-align: center !important;
}
  .field_texts {
    margin-bottom: 0px;
    font-family: 'Open sans', sans-serif;
    color: 'black';
    font-size: 12px;
    letter-spacing: .1px;
  }
  .field_layout {
    width: 100%; 
    margin-bottom: 10px
  }
  .dialog_card_container {
    padding: 5%
  }
</style>